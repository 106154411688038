import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import ui from "../../../../util/ui";
import { validateForm } from "../../../../util/validation";

import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import SelectInput from "../../../atoms/select-input";

class RegisterCreate extends React.Component {
  static defaultProps = {
    onSave: () => {}
  };

  constructor(props) {
    super(props);
    
    this.state = {
      locations: [],
    };

    this.form = React.createRef();
  }
  
  componentDidMount() {
    http
      .get(API_HOST + API_ROOT + 'locations', true)
      .then(response => {
        console.log(response);
        
        this.setState({
          locations: response.result
        });
      });
  }
  
  validateForm(form, data) {

    validateForm(form, data, {
      title: ['required'],
      amount: ['required', 'number'],
      location: ['required']
    });
  }

  onSubmit(registerData) {
    http.post(API_HOST+API_ROOT+'registers', registerData, true
    ).then(response => {
      if (response.success) {
        this.props.onSave(response.result);
      }
    }).catch(error => {
      ui.notify('There was an error, register couldn\'t be created');
    });
  }

  render() {
    let options = {};
    
    this.state.locations.forEach(location => {
      options[location.hid] = location.name;
    });
    
    return (
        <Form
            ref={this.form}
            validate={(form, data) => this.validateForm(form, data)}
            onSubmit={(data) => this.onSubmit(data)}
            submitButtonText={'Create'}
        >
          <TextInput name="title" label="Title" default="Cash Register" showRequiredIndicator={true} />
          <TextInput name="amount" label="Start amount" showRequiredIndicator={true} focus={true} />
          <SelectInput
              name="location"
              label="Location"
              showRequiredIndicator={true}
              options={options}
          />
        </Form>
    );
  }
}

export default RegisterCreate;