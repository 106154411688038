import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import DataValue from "../../data-value";
import {dateTime, price} from "../../../../util/formatting";
import Grid from "../../grid";
import Window from "../../window";
import ButtonGroup from "../../button-group";
import Button from "../../../atoms/button";
import RegisterTransactionItems from "../register-transaction-items";
import {validateForm} from "../../../../util/validation";
import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import InputStack from "../../input-stack";
import StatusLabel from "../../../atoms/status-label";
import ui from "../../../../util/ui";
import HiddenInput from "../../../atoms/hidden-input";

class RegisterEdit extends React.Component
{
  static defaultProps = {
    register: {},
    onSave: () => {},
    onRegisterTransactionCreate: () => {},
    onOpenClosePopup: (register) => {}
  };

  constructor(props) {
    super(props);
    
    this.state = {
      register: this.props.register,
      collected: this.props.register.collected,
    };

    this.registerEditForm = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.register && (prevProps.register !== this.props.register)) {
      this.fetchRegister();
    }
  }

  componentDidMount() {
    this.fetchRegister();
  }

  fetchRegister() {
    http.request({
      url: API_HOST+API_ROOT + 'registers/' + this.props.register.id
    }, true ).then(response => {
      this.setState({
        register: response.result,
        collected: response.result.collected,
        expected_amounts: response.result.expected_amounts,
      });
    });
  }

  validateForm(form, data) {
    validateForm(form, data, {
      cash_counted: ['required', 'number'],
      discount_counted: ['required', 'number'],
      card_counted: ['required', 'number'],
      mollie_counted: ['required', 'number'],
    });
  }

  handleChange(fieldName, value) {
    this.setState({[fieldName]: value});
  }

  onSubmit(registerData) {
    http.patch(API_HOST+API_ROOT+'registers/'+this.state.register.id, registerData, true
    ).then(response => {
      if (response.success) {
        this.props.onSave(response.result);
      }
    }).catch(error => {
      ui.notify('There was an error, register couldn\'t be closed');
    });
  }

  render() {
    let register = this.state.register;
    
    if (! register) {
      return;
    }
    
    return (
        <Grid cols={'5fr 3fr'}>
          <Grid rows={'1fr 4fr'}>
            <Window title={'Payment Tally'} modifiers={'frame'} header={
              <ButtonGroup>
                <Button modifiers={'small'} text="Close"
                        onClick={() => this.props.onOpenClosePopup(register)} />
              </ButtonGroup>
            }>
              <div className="list">
                <div className="list__items">
                <span className="header">
                  <span className="header-column">Payment type</span>
                  <span className="header-column">Expected</span>
                  <span className="header-column">Counted</span>
                  <span className="header-column">Difference</span>
                </span>
                {Object.keys(this.state.collected).map((method) => {
                  let collected = this.state.collected[method];
                  let counted = this.state.counted?.[method] ?? 0;
                  let difference = counted - collected;
                  
                  return (
                      <div className="list__item">
                        <div className="list-item">
                          <span className="list-item__column">{method}</span>
                          <span className="list-item__column">{price(collected)}</span>
                          <span className="list-item__column">{price(counted)}</span>
                          <span className="list-item__column">
                            {( difference < 0 ?
                                <StatusLabel modifiers={'error'} title={price(difference)} /> :
                                price(difference)
                            )}
                          </span>
                        </div>
                      </div>
                  )})}
                </div>
              </div>
            </Window>
            <Window title={'Transactions'} modifiers={'frame'} header={
              <ButtonGroup>
                <Button text="In" onClick={e => this.props.onRegisterTransactionCreate(this.state.register, 'in')}/>
                <Button text="Out" onClick={e => this.props.onRegisterTransactionCreate(this.state.register, 'out')} />
              </ButtonGroup>
            }>
              <RegisterTransactionItems items={register.register_transactions}/>
            </Window>
          </Grid>
          <Grid rows={'1fr auto'}>
            <Window title={'Summary'} modifiers={'frame'}>
              <DataValue title="Created">
                {dateTime(register.created)}
              </DataValue>
              {register.location && (
                <DataValue title="Location">
                  {register.location.name}
                </DataValue>
              )}
              <DataValue title="Total sales">
                {price(register.total_sales)}
              </DataValue>
            </Window>
            
          </Grid>
        </Grid>
    );
  }
}

export default RegisterEdit;