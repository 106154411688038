import React from 'react';

import {price} from '../../../../util/formatting';

class RegisterTransactionItems extends React.Component
{
  static defaultProps = {
    items: [],
  };

  render() {
    if (! this.props.items) {
      return <div></div>;
    }

    return (
        <div className='list'>
          <div className="list__items">
            {this.props.items.map((item, key) => {
              return (
                  <div key={key} className="list__item">
                    <div className="list-item">
                      <div className="list-item__column">
                        {item.note}
                      </div>
                      <div className="list-item__column">
                        {item.type}
                      </div>
                      <div className="list-item__column">
                        {price(item.amount)}
                      </div>
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
    );
  }
}

export default RegisterTransactionItems;