import connect from "react-redux/es/connect/connect";
import { updateList } from "../../../../state/modules/resources";

import React from 'react';

import Window from "../../window/index";
import List from "../../list/index";
import Header from "../../header";
import HeaderColumn from "../../../atoms/header-column";
import Pagination from "../../pagination";
import Grid from "../../grid";
import RegisterListItem from "../register-list-item";
import DateRangeFilter from "../../date-range-filter";

class RegisterIndex extends React.Component {

  static defaultProps = {
    onRegisterView: () => {}
  };

  getListHeader() {

    return (
      <Header listId={'registers'}>
        <HeaderColumn title="Register" />
        <HeaderColumn title="Created" />
        <HeaderColumn title="Closed" />
        <HeaderColumn title="Total sales" />
        <HeaderColumn title="Total expected" />
        <HeaderColumn title="Actual total" />
        <HeaderColumn title="Difference" />
        <HeaderColumn />
      </Header>
    );
  }

  getList() {
    return (
      <List id={'registers'}
            model={'registers'}
            header={this.getListHeader()}
            onItemClick={register => this.props.onRegisterView(register)}
      >
        <RegisterListItem />
      </List>
    );
  }

  render() {
    return (
      <Window
        title="Registers"
        header={
          <DateRangeFilter listId={'registers'} filterKey={'filter_created'} />
        }
      >
        <Grid rows="auto 1fr">
          <Window modifiers={'depth'} footer={<Pagination listId={'registers'} model={'register'}/>}>
            {this.getList()}
          </Window>
        </Grid>
      </Window>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterIndex);