import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

import Button from "../../atoms/button";

class Form extends React.Component {

  static defaultProps = {
    footer: null,
    showSubmitButton: true,
    submitButtonText: 'Submit',
    modifiers: null,
    clearOnSubmit: false,
    validate: () => {},
    onSubmit: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      errorMessages: {}
    };

    this.childRefs = [];

    if (this.hasMultipleChildren()) {
      this.props.children.forEach((child) => {
        this.childRefs.push(React.createRef());
      });

    } else {
      this.childRefs.push(React.createRef());
    }

    this.isValid = false;
  }

  getInputByRef(ref) {

    return this.findNode(ref, this.childRefs);
  }

  findNode(id, node) {

    let result;
    let currentChild;
    let currentNode = node.childRefs || node;

    if (currentNode.length) {

      for (let i = 0; i < currentNode.length; i++) {
        currentChild = currentNode[i];
        result = this.findNode(id, currentChild.current);

        if (result) {
          return result;
        }
      }

    } else if (currentNode.props.name && currentNode.props.name === id) {
      return currentNode;
    }

    return false;
  }

  submit() {

    let data = this.handleSubmit();

    this.clearErrorMessages(() => {

      this.props.validate(this, data);

      if (this.isValid) {
        this.props.onSubmit(data);

        if (this.props.clearOnSubmit) {
          this.clearFieldData();
        }
      }
    });
  }

  onSubmit(e) {

    e.preventDefault();
    this.submit();
  }

  clearFieldData() {

    if (this.hasMultipleChildren()) {
      this.props.children.forEach((child, i) => {
        this.childRefs[i].current.updateValue('');
      });
    }

    this.childRefs[0].current.updateValue('');
  }

  clearErrorMessages(cb) {

    this.isValid = true;
    this.setState({errorMessages: {}}, cb);
  }

  setErrorMessage(name, message) {

    this.isValid = false;

    let errorMessages = this.state.errorMessages;

    errorMessages[name] = message;

    this.setState({
      errorMessages: errorMessages
    });
  }

  handleSubmit() {

    let data = {};

    if (this.hasMultipleChildren()) {

      this.props.children.forEach((child, i) => {
        this.childRefs[i].current.handleSubmit(data);
      });

      return data;
    }

    this.childRefs[0].current.handleSubmit(data);

    return data;
  }

  hasMultipleChildren() {

    return this.props.children.length;
  }

  renderErrorMessage(inputName) {

    if (this.state.errorMessages[inputName]) {
      return (
        <div className="form__error-message">
          {this.state.errorMessages[inputName]}
        </div>
      );
    }
  }

  renderChildren() {

    if (this.hasMultipleChildren()) {

      return this.props.children.map((child, i) => {
        const TagName = child.type;
        
        if (!child) {
          return;
        }
        
        return (
          <div key={i} className="form__input">
            <TagName ref={this.childRefs[i]} {...child.props} renderErrorMessage={this.renderErrorMessage.bind(this)} />
            {this.renderErrorMessage(child.props.name)}
          </div>
        );
      });
    }

    const TagName = this.props.children.type;
    return (
      <div className="form__input">
        <TagName ref={this.childRefs[0]} {...this.props.children.props} renderErrorMessage={this.renderErrorMessage.bind(this)} />
        {this.renderErrorMessage(this.props.children.props.name)}
      </div>
    );
  }

  render() {

    let footerStyle = {
      display: 'none'
    };

    if (this.props.footer || this.props.showSubmitButton) {
      footerStyle = {};
    }

    let footer = (
      <div className="form__footer" style={footerStyle}>
        {this.props.footer}
        <Button type="submit" text={this.props.submitButtonText} />
      </div>
    );

    return (
      <form className={"form"+modifiers('form', this.props.modifiers)} onSubmit={e => this.onSubmit(e)} autoComplete={'off'}>
        <div className="form__content">
          {this.renderChildren()}
        </div>
        {footer}
      </form>
    );
  }
}

export default Form;