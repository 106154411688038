import React from 'react';
import {dateTime, price} from "../../../../util/formatting";
import IconButton from "../../../atoms/icon-button";
import RegisterInvoicePrint from "../register-invoice-print";
import {API_HOST, API_ROOT, http} from "../../../../util/http";

class RegisterListItem extends React.Component {
  static defaultProps = {
    model: {},
  };

  handlePrint(model) {
    const printableElement = document.getElementById('printme-'+model.id);
    const printalbeHtml = '<html><head><title>Print '+model.hid+'</title></head><body style="font-family: Arial">' + printableElement.innerHTML + '</body></html>';

    const printWindow = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
    printWindow.document.write(printalbeHtml);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  render() {
    let totalCollected = parseFloat(this.props.model.total_collected);
    let totalCounted = parseFloat(this.props.model.total_counted);

    return (
      <React.Fragment>
        <div className="list-item">
          <span className="list-item__column">
            {this.props.model.title} {this.props.model.id}
          </span>
          <span className="list-item__column">
            {dateTime(this.props.model.created)}
          </span>
          <span className="list-item__column">
            {this.props.model.closed > 0 ? dateTime(this.props.model.closed) :'-'}
          </span>
          <span className="list-item__column">
            {price(parseFloat(this.props.model.total_sales))}
          </span>
          <span className="list-item__column">
            {price(totalCollected)}
          </span>
          <span className="list-item__column">
            {price(totalCounted)}
          </span>
          <span className="list-item__column">
            {price(totalCounted - totalCollected)}
          </span>
          <span className="list-item__column">
            <IconButton iconType={'print'} onClick={() => this.handlePrint(this.props.model)} />
            <RegisterInvoicePrint printableId={'printme-'+this.props.model.id} register={this.props.model} />
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterListItem;