import './index.scss';

import React from 'react';
import {dateTime, price} from "../../../../util/formatting";

class RegisterInvoicePrint extends React.Component
{
  static defaultProps = {
    printalbeId: null,
    register: null
  };

  constructor(props) {
    super(props);
  }

  calculateVatResults(results, price, percentage = 21) {
    if (percentage in results === false) {
      results[percentage] = {
        excl_vat: 0,
        incl_vat: 0,
        vat: 0,
      };
    }

    let incl_vat = parseFloat(price);
    let excl_vat = incl_vat / (percentage/100+1);

    results[percentage].excl_vat += excl_vat;
    results[percentage].incl_vat += incl_vat;
    results[percentage].vat += (incl_vat - excl_vat);
  }

  render() {
    let register = this.props.register;
    let results = {};

    register.orders && register.orders.forEach(order => {
      // Go through all releaseses (21%)
      order.order_releases && order.order_releases.forEach(orderRelease => {
        this.calculateVatResults(results, orderRelease.price);
      });

      // Go through all giftcards (21%)
      order.giftcards && order.giftcards.forEach(giftcard => {
        this.calculateVatResults(results, giftcard.value);
      });

      // Go through all custom product (variable)
      order.custom_products && order.custom_products.forEach(customProduct => {
        this.calculateVatResults(results, customProduct.price, customProduct.custom_product_type.vat_rate);
      });

      // Go through all client orders (21%)
      order.order_client_orders && order.order_client_orders.forEach(orderClientOrder => {
        this.calculateVatResults(results, orderClientOrder.price);
      });
    });
    
    return (<div></div>);

    return (
      <div className={'printable'} id={this.props.printableId}>
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="50%">
                        <h1>{register.hid}</h1>
                      </td>
                      <td width="50%" align="right">
                        <strong>{dateTime(register.created)} to {dateTime(register.closed)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td width="100%" style={{borderBottom: '1px solid black'}} colSpan={2}></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%" style={{tableLayout: 'fixed'}}>
                  <thead>
                    <tr>
                      <th align="left">Payment type</th>
                      <th align="right">Expected</th>
                      <th align="right">Counted</th>
                      <th align="right">Difference</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="left">Cash</td>
                      <td align="right">{price(register.expected_cash_amount)}</td>
                      <td align="right">{price(register.cash_counted)}</td>
                      <td align="right">{price(register.cash_counted - register.expected_cash_amount)}</td>
                    </tr>
                    <tr>
                      <td align="left">Card</td>
                      <td align="right">{price(register.card_amount)}</td>
                      <td align="right">{price(register.card_counted)}</td>
                      <td align="right">{price(register.card_counted-register.card_amount)}</td>
                    </tr>
                    <tr>
                      <td align="left">Mollie</td>
                      <td align="right">{price(register.mollie_amount)}</td>
                      <td align="right">{price(register.mollie_counted)}</td>
                      <td align="right">{price(register.mollie_counted-register.mollie_amount)}</td>
                    </tr>
                    <tr>
                      <td align="left">Discount</td>
                      <td align="right">{price(register.discount_amount)}</td>
                      <td align="right">{price(register.discount_counted)}</td>
                      <td align="right">{price(register.discount_counted-register.discount_amount)}</td>
                    </tr>
                    <tr>
                      <td width="100%" style={{borderBottom: '1px solid black'}} colSpan={4}></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table align="right" width="25%">
                  <tbody>
                    <tr>
                      <td>Total sales:</td>
                      <td align="right">{price(register.total_sales)}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Taxes collected:</td>
                    </tr>
                    {results && Object.entries(results).map(([key, value]) => {
                      return (
                        <tr key={key}>
                          <td>{key} %</td>
                          <td align="right">{price(value.vat.toFixed(2))}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td width="100%" height="30">&nbsp;</td>
            </tr>
            <tr>
              <td width="100%">
                <h3>Transactions</h3>
              </td>
            </tr>
            <tr>
              <td width="100%" style={{borderTop: '1px solid black'}}></td>
            </tr>
            <tr>
              <td>
                <table width="100%" style={{tableLayout: 'fixed'}}>
                  <thead>
                    <tr>
                      <th align="left">Transaction</th>
                      <th align="left">Type</th>
                      <th align="right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {register.register_transactions && register.register_transactions.map((transaction, key) => {
                      return (
                        <tr key={key}>
                          <td align="left">{transaction.note}</td>
                          <td align="left">{transaction.type}</td>
                          <td align="right">{transaction.amount}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td width="100%" style={{borderBottom: '1px solid black'}} colSpan={3}></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td width="100%" height="30">&nbsp;</td>
            </tr>
            <tr>
              <td width="100%">
                <h3>VAT Summary</h3>
              </td>
            </tr>
            <tr>
              <td width="100%" style={{borderTop: '1px solid black'}}></td>
            </tr>
            <tr>
              <td>
                <table width="100%" style={{tableLayout: 'fixed'}}>
                  <thead>
                    <tr>
                      <th align="right">Total excl. VAT</th>
                      <th align="right">VAT</th>
                      <th align="right">Total incl. VAT</th>
                      <th align="right">VAT rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(results).map(([key, value]) => {
                      return (
                        <tr key={key}>
                          <td align="right">{price(value.excl_vat.toFixed(2))}</td>
                          <td align="right"> {price(value.vat.toFixed(2))}</td>
                          <td align="right">{price(value.incl_vat.toFixed(2))}</td>
                          <td align="right">{key} %</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td width="100%" style={{borderBottom: '1px solid black'}} colSpan={4}></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default RegisterInvoicePrint;