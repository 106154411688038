import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

export default class SelectInput extends React.Component {

    static defaultProps = {
        options: [],
        default: '',
        label: '',
        name: '',
        modifiers: null,
        disabled: false,
        showRequiredIndicator: false,
        focus: false,
        onChange: () => {},
        onBlur: () => {},
        onFocus: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            id: 'input-' + this.props.name + '-' + Math.random().toString(36).substring(7),
            value: this.props.default
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.default !== prevProps.default) {
            this.setState({
                value: this.props.default
            });
        }
    }
    
    getOptions() {
        return this.state.options.map((option, key) => <option key={key} value={option}>{option}</option>);
    }

    getValue() {
        return this.state.value;
    }

    handleChange(e) {
        this.setState({value: e.target.value});
        this.props.onChange(this.state.value);
    }

    updateValue(value = '') {
        this.setState({value: value});
    }

    handleSubmit(data) {
        data[this.props.name] = this.getValue();
    }

    render() {
        let label;
        let requiredIndicator;

        if (this.props.showRequiredIndicator) {
            requiredIndicator = <span className="text-input__required-indicator">*</span>;
        }

        if (this.props.label) {
            label = (
                <label htmlFor={this.state.id} className="text-input__label">
                    {this.props.label}
                    {requiredIndicator}
                </label>
            );
        }

        return (
            <div className={"text-input" + modifiers('text-input', this.props.modifiers)}>
                {label}
                <select
                    id={this.state.id}
                    name={this.props.name}
                    className="pagination__select"
                    defaultValue={null}
                    disabled={this.props.disabled}
                    onChange={this.handleChange}
                    onKeyUp={this.handleChange}
                    onBlur={e => this.props.onBlur(e)}
                    onFocus={e => this.props.onFocus(e)}
                    autoFocus={this.props.focus}
                >
                    {!this.props.defaultValue && (
                        <option value="" selected disabled hidden></option>
                    )}
                    {this.props.options && Object.keys(this.props.options).map(key => {
                        return (
                            <option key={key} value={key}>{this.props.options[key]}</option>
                        );
                    })}
                </select>
            </div>
        );
    }
}